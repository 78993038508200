import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, IconButton, Paper, Box, useTheme, useMediaQuery } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DOMPurify from 'dompurify';
import "./style.scss";
import { useUser } from "../contexts/UserContext";

const WellbeingReport = () => {
  const location = useLocation();
  const { adminReport } = location.state || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();
  const navigate = useNavigate();

  const goBackReport = () => navigate("/admin-view");

  const sanitizeAndParseHTML = (htmlContent) => {
    if (!htmlContent) return null;

    const sanitizedContent = DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'strong', 'em', 'br'],
      ALLOWED_ATTR: []
    });

    return (
      <div 
        dangerouslySetInnerHTML={{ __html: sanitizedContent }} 
        style={{
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(3)
          },
          '& p': {
            marginBottom: theme.spacing(2)
          },
          '& ul, & ol': {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(2)
          }
        }}
      />
    );
  };

  return (
    <Box className="prevent_overflow" sx={{ p: 3 }}>
      <IconButton 
        onClick={goBackReport}
        sx={{ mb: 2 }}
        aria-label="go back"
      >
        <ArrowBackIcon />
        {!isSmallScreen && (
          <Typography 
            color="primary.main" 
            variant='subtitle1' 
            sx={{ ml: 1 }}
          >
            Go Back
          </Typography>
        )}
      </IconButton>

      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4" color="primary.main" gutterBottom>
            Wellbeing Report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={10} lg={8}>
          <Paper elevation={3}>
            <Box bgcolor="primary.main" p={2} borderRadius="10px 10px 0 0">
              <Typography variant="h5" color="white">
                ESG Wellbeing Analysis
              </Typography>
            </Box>
            <Box p={isSmallScreen ? 2 : 4}>
              {adminReport ? (
                sanitizeAndParseHTML(adminReport)
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No report available at this time. Please check back later or contact support if you believe this is an error.
                </Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WellbeingReport;