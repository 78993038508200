import React, { useState, useEffect, useCallback } from 'react';
import { 
  validateGraphCode, 
} from '../components/graphUtils';
import { 
  Grid, 
  TextField, 
  Button, 
  Typography, 
  Select, 
  MenuItem, 
  FormControl,
  Card,
  Box,
  Paper,
  Divider,
  IconButton,
  Tooltip,
  InputLabel,
  Chip,
  FormHelperText,
  InputAdornment,


} from '@mui/material';
import CollapsibleMetricsGrid from '../components/dataMetric';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import GroupIcon from '@mui/icons-material/Group';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './shared/LoadingSpinner';
import { useUser } from '../contexts/UserContext';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import { URL } from '../Helpers';
import DynamicChart from '../components/DynamicChart';
// Styled Components with enhanced aesthetics
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#FFF7F1',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  height: '100%',
  transition: 'all 0.3s ease'
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4C6EA2',
  color: 'white',
  padding: '10px 24px',
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#446494',
    boxShadow: '0 4px 12px rgba(76, 110, 162, 0.2)',
  },
  margin: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  transition: 'all 0.2s ease'
}));

const CustomPicker = styled(DatePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4C6EA2',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3F444B40',
      transition: 'all 0.2s ease'
    }
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px'
  }
}));

const PromptField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4C6EA2',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3F444B40',
      transition: 'all 0.2s ease'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4C6EA2',
    }
  }
});

const CustomSelect = styled(FormControl)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4C6EA2',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3F444B40',
      transition: 'all 0.2s ease'
    }
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s ease'
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 24px rgba(0, 0, 0, 0.08)'
  }
}));

const GenerateButton = styled(Button)(({ theme }) => ({
  height: '100%',
  minHeight: '56px',
  backgroundColor: '#4C6EA2',
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  boxShadow: '0 4px 12px rgba(76, 110, 162, 0.2)',
  '&:hover': {
    backgroundColor: '#446494',
    boxShadow: '0 6px 16px rgba(76, 110, 162, 0.3)',
  },
  '&.Mui-disabled': {
    backgroundColor: '#E3DBD5',
    color: '#3F444B'
  },
  transition: 'all 0.2s ease'
}));


// Constants
const EXAMPLE_PROMPTS = [
  "Show me a line chart of energy levels over time",
  "Create a pie chart of session topics distribution",
  "Display department-wise session counts",
  "Visualize the trend of session durations",
  "Show communication distribution across all sessions",
  "Compare average energy levels by department"
];

// Additional styled components
const ExamplePromptChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#FFF7F1',
  border: '1px solid #E3DBD5',
  borderRadius: '8px',
  cursor: 'pointer',
  width: '100%',
  height: 'auto',
  padding: '8px 4px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: '#E3DBD5',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  },
  '& .MuiChip-label': {
    whiteSpace: 'normal',
    display: 'block',
    padding: '4px 8px',
    fontSize: '0.875rem'
  },
  '& .MuiChip-icon': {
    color: '#4C6EA2'
  }
}));

// EmptyGraphState Component
const EmptyGraphState = () => (
  <Box sx={{ 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    p: 4,
    backgroundColor: '#FFF7F1' 
  }}>
    <BarChartIcon sx={{ 
      fontSize: 80, 
      color: '#4C6EA2', 
      mb: 3,
      opacity: 0.8 
    }} />
    <Typography variant="h5" color="#4C6EA2" sx={{ 
      mb: 2,
      fontWeight: 500 
    }}>
      Ready to Visualize Your Data
    </Typography>
    <Typography 
      color="text.secondary" 
      sx={{ 
        maxWidth: '500px', 
        textAlign: 'center',
        lineHeight: 1.6 
      }}
    >
      Use the prompt field above to generate insightful visualizations.
      Choose from example prompts or create your own based on available metrics.
    </Typography>

  </Box>
);

const DynamicAdmin = () => {
  const [sessionData, setSessionData] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prompt, setPrompt] = useState('');
  const [graphCode, setGraphCode] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();
  const [dashboardStats, setDashboardStats] = useState({
    totalSessions: 0,
    avgDuration: 0,
    activeUsers: 0,
    topDepartment: ''
  });

    // Initial data fetch
    const fetchInitialData = useCallback(async () => {
      const idToken = await user.getIdToken();
      try {
        const response = await fetch(`${URL}/get_users_sessions_by_company`, {
          headers: {
            "Authorization": `Bearer ${idToken}`
          },
        });
  
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
        const data = await response.json();
        setSessionData(data);
        
        // Set departments
        const uniqueDepartments = Array.from(new Set(data.departments || []));
        setDepartments(uniqueDepartments);
        
        // Set initial dates from session times
        const sortedTimes = (data.session_times || []).sort((a, b) => new Date(a) - new Date(b));
        if (sortedTimes.length > 0) {
          setStartDate(new Date(sortedTimes[0]));
          setEndDate(new Date(sortedTimes[sortedTimes.length - 1]));
        }
  
        updateDashboardStats(data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    }, [user]);
  
    // Fetch filtered data
    const fetchFilteredData = useCallback(async () => {
      if (!startDate || !endDate) return;
  
      setLoading(true);
      const idToken = await user.getIdToken();
      
      try {
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        const departmentParam = selectedDepartment ? `&department=${selectedDepartment}` : '';
        
        const response = await fetch(
          `${URL}/get_users_sessions_by_company?start_date=${formattedStartDate}&end_date=${formattedEndDate}${departmentParam}`,
          {
            headers: {
              "Authorization": `Bearer ${idToken}`
            },
          }
        );
  
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
        const data = await response.json();
        setSessionData(data);
        updateDashboardStats(data);
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      } finally {
        setLoading(false);
      }
    }, [user, startDate, endDate, selectedDepartment]);
  
    // Update dashboard stats helper
    const updateDashboardStats = (data) => {

      // calculate the average duration of sessions
      const avgDuration = data.session_durations.reduce((a, b) => a + b, 0) / data.session_durations.length;
      // calculate the number of users that did a session in the past week
      const activeUsers = data.users_list.filter(user => user.last_session_date).length;
      // calculate department with the most sessions
      const topDepartment = data.departments.reduce((a, b) => (data.session_count[a] > data.session_count[b] ? a : b));

      setDashboardStats({
        totalSessions: data.session_count || 0,
        avgDuration: avgDuration || 0,
        activeUsers: activeUsers || 0,
        topDepartment: topDepartment || 'N/A'
      });
    };
  
    // Effect for initial data load
    useEffect(() => {
      fetchInitialData();
    }, [fetchInitialData]);
  
    // Effect for filter changes
    useEffect(() => {
      if (startDate && endDate) {
        fetchFilteredData();
      }
    }, [fetchFilteredData, startDate, endDate, selectedDepartment]);
  
    // Handle date changes
    const handleStartDateChange = (date) => {
      const newDate = date?.toDate() || null;
      setStartDate(newDate);
    };
  
    const handleEndDateChange = (date) => {
      const newDate = date?.toDate() || null;
      setEndDate(newDate);
    };
  
    // Handle department change
    const handleDepartmentChange = (event) => {
      const newDepartment = event.target.value === "All Departments" ? null : event.target.value;
      setSelectedDepartment(newDepartment);
    };


  const generateGraph = async () => {
    if (!prompt.trim()) return;
  
    const idToken = await user.getIdToken();
    try {
      setLoading(true);
      
      const response = await fetch(`${URL}/create_graph`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          prompt,
          data: sessionData
        })
      });
  
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
      const { graph_code } = await response.json();
      
      // Just set the graph code and processed data - don't try to execute it here
      if (validateGraphCode(graph_code)) {
        setGraphCode(graph_code);
      } else {
        throw new Error('Invalid graph code received');
      }
    } catch (error) {
      console.error("Error generating graph:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleAddEmployee = () => {
    navigate("/add-employee");
  };

  const handleShowReport = () => {
    navigate("/wellbeing-report", { state: { adminReport: sessionData?.admin_report } });
  };
  return (
    <Box sx={{ p: 4, backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
      <StyledCard>
        <Grid container spacing={4}>
          {/* Header Section */}
          <Grid item xs={12} sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2,
            flexWrap: 'wrap',
            gap: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ 
                backgroundColor: '#4C6EA2', 
                borderRadius: '12px', 
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <TimelineIcon sx={{ fontSize: 32, color: '#ffffff' }} />
              </Box>
              <Typography variant="h4" sx={{ 
                color: '#4C6EA2', 
                fontWeight: 600,
                letterSpacing: '-0.5px' 
              }}>
                Admin Dashboard
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Tooltip title="Add new employees to the system" arrow>
                <HeaderButton 
                  onClick={handleAddEmployee} 
                  startIcon={<PersonAddIcon />}
                  variant="contained"
                >
                  Add Employee
                </HeaderButton>
              </Tooltip>
              <Tooltip title="View comprehensive wellbeing report" arrow>
                <HeaderButton 
                  onClick={handleShowReport} 
                  startIcon={<AssessmentIcon />}
                  variant="contained"
                >
                  View Report
                </HeaderButton>
              </Tooltip>
            </Box>
          </Grid>
  
          {/* Stats Section */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard elevation={0}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <TimelineIcon sx={{ color: '#4C6EA2' }} />
                      <Typography variant="h6" color="#4C6EA2" sx={{ fontWeight: 500 }}>
                        Total Sessions
                      </Typography>
                    </Box>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 600, 
                      color: '#3F444B',
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 1 
                    }}>
                      {dashboardStats.totalSessions}
                      <Typography variant="subtitle1" sx={{ color: '#666', mb: 1 }}>
                        sessions
                      </Typography>
                    </Typography>
                  </Box>
                </StatCard>
              </Grid>
  
              <Grid item xs={12} sm={6} md={3}>
                <StatCard elevation={0}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <AccessTimeIcon sx={{ color: '#4C6EA2' }} />
                      <Typography variant="h6" color="#4C6EA2" sx={{ fontWeight: 500 }}>
                        Avg Duration
                      </Typography>
                    </Box>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 600, 
                      color: '#3F444B',
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 1 
                    }}>
                      {dashboardStats.avgDuration}
                      <Typography variant="subtitle1" sx={{ color: '#666', mb: 1 }}>
                        min
                      </Typography>
                    </Typography>
                  </Box>
                </StatCard>
              </Grid>
  
              <Grid item xs={12} sm={6} md={3}>
                <StatCard elevation={0}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <GroupIcon sx={{ color: '#4C6EA2' }} />
                      <Typography variant="h6" color="#4C6EA2" sx={{ fontWeight: 500 }}>
                        Active Users
                      </Typography>
                    </Box>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 600, 
                      color: '#3F444B',
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 1 
                    }}>
                      {dashboardStats.activeUsers}
                      <Typography variant="subtitle1" sx={{ color: '#666', mb: 1 }}>
                        users
                      </Typography>
                    </Typography>
                  </Box>
                </StatCard>
              </Grid>
  
              <Grid item xs={12} sm={6} md={3}>
                <StatCard elevation={0}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <BusinessIcon sx={{ color: '#4C6EA2' }} />
                      <Typography variant="h6" color="#4C6EA2" sx={{ fontWeight: 500 }}>
                        Top Department
                      </Typography>
                    </Box>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 600, 
                      color: '#3F444B',
                      fontSize: '1.8rem'
                    }}>
                      {dashboardStats.topDepartment}
                    </Typography>
                  </Box>
                </StatCard>
              </Grid>
            </Grid>
          </Grid>
  
          {/* Filters Section */}
          <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 3, color: '#4C6EA2', fontWeight: 500 }}>
              Data Filters
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomPicker
                    label="Start Date"
                    value={startDate ? dayjs(startDate) : null}
                    onChange={handleStartDateChange}
                    fullWidth
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        helperText: 'Filter from date'
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomPicker
                    label="End Date"
                    value={endDate ? dayjs(endDate) : null}
                    onChange={handleEndDateChange}
                    fullWidth
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        helperText: 'Filter to date'
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect fullWidth>
                  <InputLabel>Department</InputLabel>
                  <Select
                    value={selectedDepartment || "All Departments"}
                    onChange={handleDepartmentChange}
                    label="Department"
                  >
                    <MenuItem value="All Departments">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon sx={{ color: '#4C6EA2' }} />
                        All Departments
                      </Box>
                    </MenuItem>
                    <Divider />
                    {departments.map((dept) => (
                      <MenuItem key={dept} value={dept}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <CircleIcon sx={{ fontSize: 8, color: '#4C6EA2' }} />
                          {dept}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Filter by department</FormHelperText>
                </CustomSelect>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
  
          {/* Data Guide Section */}          
          <Grid item xs={12}>
            <StyledPaper>
              <Box sx={{ mb: 4 }}>
              <CollapsibleMetricsGrid />
              </Box>
  
              <Divider sx={{ my: 4 }} />
  
              {/* Graph Generation Section */}
              <Box>
                <Typography variant="h6" sx={{ 
                  mb: 3, 
                  color: '#4C6EA2', 
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <BarChartIcon /> Generate Visualization
                </Typography>
  
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
                    Example Prompts:
                  </Typography>
                  <Grid container spacing={1}>
                    {EXAMPLE_PROMPTS.map((prompt, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <ExamplePromptChip
                          label={prompt}
                          onClick={() => setPrompt(prompt)}
                          icon={<ChevronRightIcon />}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
  
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} md={9}>
                    <PromptField
                      fullWidth
                      label="Describe the graph you want to generate"
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      variant="outlined"
                      placeholder="Example: Show me a line chart of daily sessions over time"
                      multiline
                      rows={2}
                      InputProps={{
                        endAdornment: prompt && (
                          <InputAdornment position="end">
                            <Tooltip title="Clear prompt">
                              <IconButton onClick={() => setPrompt('')} edge="end">
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      Use the example prompts above or create your own based on the available metrics
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <GenerateButton
                      fullWidth
                      variant="contained"
                      onClick={generateGraph}
                      disabled={!prompt.trim()}
                      startIcon={<BarChartIcon />}
                    >
                      Generate Graph
                    </GenerateButton>
                  </Grid>
                </Grid>
              </Box>
            </StyledPaper>
          </Grid>
  
          {/* Graph Display Section */}
          <Grid item xs={12}>
            <StyledPaper sx={{ height: '500px', p: 0, overflow: 'hidden' }}>
              {loading ? (
                <Box sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)' 
                }}>
                  <LoadingSpinner />
                </Box>
              ) : graphCode ? (
                <Box sx={{ height: '100%', p: 3 }}>
                  <DynamicChart 
                    graphCode={graphCode}
                    data={sessionData}
                    loading={loading}
                  />
                </Box>
              ) : (
                <EmptyGraphState />
              )}
            </StyledPaper>
          </Grid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

export default DynamicAdmin;