import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { URL, sendEnergyLevel, getStorageMonthDays, getStorageMonths, getStorageSessionCalendar, saveStorageMonthDays, saveStorageMonths, saveStorageSessionCalendar } from "../Helpers";
import { setDoc, db, doc, getDoc, collection, query, getDocs, getDownloadURL, storage, ref, where } from "../Firebase";
import { Button, Grid, SvgIcon, Typography, Slider } from "@mui/material";
import { useUser } from '../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useErrorBoundary } from "react-error-boundary";
import { chunk } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/system';
import { ReactComponent as ArrowBack } from '../data/arrow_back.svg';
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import LoadingSpinner from "./shared/LoadingSpinner";
import ProgressBar from '../data/ProgressBar.png';
import Mysession from '../data/Layer_1.png';
import "./CalendarStyle.scss";

const energyColors = {
  5: { background: '#53E394', text: '#2E8354' },
  4: { background: '#C4F79F', text: '#597445' },
  3: { background: '#FFFF9E', text: '#9A9A00' },
  2: { background: '#FFBE8F', text: '#976745' },
  1: { background: '#FF9675', text: '#7F4330' },
};

const CustomSlider = styled(Slider)(() => ({
  '& .MuiSlider-rail': {
    background: `linear-gradient(to left, green 15%, #ffe000 40%, red 90%)`,
    height: "10px",
    paddingLeft: "10px"
  },
  '& .MuiSlider-track': {
    background: `transparent`,
    height: "10px"
  },
  '& .MuiSlider-thumb': {
    width: "25px",
    height: "25px"
  },
  '& .MuiSlider-mark': {
    backgroundColor: "#5C83BF",
    marginLeft: "5px"
  }
}));

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(false);
  const [showMoodLevelModal, setShowMoodLevelModal] = useState(false);
  const [sessionNotFound, setSessionNotFound] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [energyLevel, setEnergyLevel] = useState({});
  const [selectedBox, setSelectedBox] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const [currentView, setCurrentView] = useState('week');
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  // const [moodrepURLs, setMoodrepURLs] = useState([]);
  const [moodrepURLsByDate, setMoodrepURLsByDate] = useState({});
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const daysInMonth = Array.from({ length: currentMonth.daysInMonth() }, (_, i) => i + 1);

  const handleSliderChange = (event, newValue) => {
    setSelectedBox(newValue); // Update selectedBox state with the slider value
  };

  const fetch_calendar_monthly = async () => {
    const month = currentMonth.format("YYYY-MM");
    let storedMonths = getStorageMonths();
  
    try {
      const userID = user?.uid;
      const startOfMonth = currentMonth.startOf('month').toDate();
      const endOfMonth = currentMonth.endOf('month').toDate();
  
      // Check if data for this month is already in session storage
      if (storedMonths.includes(month)) {
        console.log("Fetching sessions for", month, "from session storage");
        const daysInMonth = getStorageMonthDays(month) || [];
        const energy_level_dict = {};
        const moodrepURLsByDate = {};
        const moodrepPromises = [];
  
        daysInMonth.forEach(day => {
          const date = `${month}-${day.padStart(2, '0')}`;
          const sessionData = getStorageSessionCalendar(date);
          if (sessionData) {
            if (sessionData.energy_level !== undefined) {
              energy_level_dict[date] = sessionData.energy_level;
            }
            if (sessionData.moodrep) {
              const moodrepRef = ref(storage, sessionData.moodrep);
              moodrepPromises.push(
                getDownloadURL(moodrepRef)
                  .then(url => {
                    moodrepURLsByDate[date] = url;
                    return { date, url };
                  })
                  .catch(error => {
                    console.error(`Failed to get download URL for ${sessionData.moodrep}`, error);
                    return { date, url: null };
                  })
              );
            }
          }
        });
  
        // Wait for all moodrep URL promises to resolve
        await Promise.all(moodrepPromises);
  
        setEnergyLevel(prevState => ({...prevState, ...energy_level_dict}));
        setMoodrepURLsByDate(prevState => ({...prevState, ...moodrepURLsByDate}));
  
        setLoading(false);
        return;
      }
  
      // If data is not in session storage, fetch from Firebase
      console.log("Fetching sessions for", month, "from Firebase");
      const sessionQuery = query(
        collection(db, 'users', userID, 'sessions'),
        where('session_time', '>=', startOfMonth),
        where('session_time', '<=', endOfMonth)
      );
  
      const sessions = await getDocs(sessionQuery);
      const moodrepURLsByDate = {};
      const energy_level_dict = {};
      const daysInMonth = [];
      const moodrepPromises = [];
  
      sessions.forEach(doc => {
        const data = doc.data();
        const date = doc.id;
        daysInMonth.push(date.split("-")[2]);
  
        if (data.energy_level !== undefined) {
          energy_level_dict[date] = data.energy_level;
        }
  
        if (data.moodrep) {
          const moodrepRef = ref(storage, data.moodrep);
          moodrepPromises.push(
            getDownloadURL(moodrepRef)
              .then(url => {
                moodrepURLsByDate[date] = url;
                return { date, url };
              })
              .catch(error => {
                console.error(`Failed to get download URL for ${data.moodrep}`, error);
                return { date, url: null };
              })
          );
        }
  
        // Store fetched sessions in session storage
        saveStorageSessionCalendar(date, data);
      });
  
      // Wait for all moodrep URL promises to resolve
      await Promise.all(moodrepPromises);
  
      setEnergyLevel(prevState => ({...prevState, ...energy_level_dict}));
      setMoodrepURLsByDate(prevState => ({...prevState, ...moodrepURLsByDate}));
  
      if (!storedMonths.includes(month)) {
        storedMonths.push(month);
        saveStorageMonths(storedMonths);
      }
      saveStorageMonthDays(month, daysInMonth);
  
      setLoading(false);
    } catch (error) {
      showBoundary(error);
      console.error("Error occurred: ", error);
      setLoading(false);
    }
  };
// Modify the handleEnergyLevelSelect function
const handleEnergyLevelSelect = useCallback(async (day, level) => {
  const date = currentMonth.date(day).format('YYYY-MM-DD');
  
  try {
    // Update Firestore
    const userID = user?.uid;
    // Update local state
    setEnergyLevel(prevLevels => ({ ...prevLevels, [date]: level }));

    // Update session storage
    const sessionData = getStorageSessionCalendar(date);
    sessionData.energy_level = level;
    sessionData.session_time = new Date(date);
    saveStorageSessionCalendar(date, sessionData);

    // Send energy level to backend
    await sendEnergyLevel(user.accessToken, date, level);

    setShowModal(false);
    setShowMoodLevelModal(false);
  } catch (error) {
    console.error("Error updating energy level:", error);
    // Handle error (e.g., show error message to user)
  }
}, [currentMonth, user, db]);

// Add this useEffect to refetch data when currentMonth changes
useEffect(() => {
  if (user) {
    fetch_calendar_monthly();
  }
}, [user, currentMonth]);

  // const imageGroups = chunk(moodrepURLs, 4);

  const handleDayClick = (day) => {
    const selectedDate = currentMonth.date(day);
    const formattedDate = selectedDate.format("YYYY-MM-DD")
    // console.log(formattedDate, day, dayjs().date(), selectedDate);

    let userID = user?.uid;

    const calendarData = getStorageSessionCalendar(formattedDate)

    if (dayjs(formattedDate).isSame(dayjs(), "day")) {

      if (calendarData?.moodrep) {
        setShowVisitModal(true)
      }
      setSelectedDay(day);
      setShowModal(true);

    }
    else if (dayjs(formattedDate).isBefore(dayjs(), "day")) {

      if (!calendarData) {
        console.log("No session with that date exists");
        return
      }

      if (!calendarData.moodrep) {
        console.log(
          "Mood level already recorded for this day, no session exists"
        );
        return;
      }

      setShowModal(true);
      setShowVisitModal(true);
      setSelectedDay(day);

    }
  };
  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.add(1, "month"));
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.subtract(1, "month"));
  };


  const getDayColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.background || "#FFFFFF";
  }, [energyLevel, currentMonth]);

  const getDayTextColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.text || "#4C6481";
  }, [energyLevel, currentMonth]);

  const getBorderColor = useCallback((day) => {
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    return energyColors[energyLevel[date]]?.text  || "#EDE4DF";
  }, [energyLevel, currentMonth]);

  const isCurrentDay = useCallback((day) => {
    const currentDate = dayjs();
    // return currentDate.date() === day && currentDate.month() === currentMonth.month() && currentDate.year() === currentMonth.year();
    return currentDate.isSame(currentMonth.date(day), "day");
  }, [currentMonth]);

  const handleBack = () => {
    setShowMoodLevelModal(false);
  }

  const getMoodRepImage = (day) => {
    // const date = day < 10 ? `${currentMonth.format('YYYY-MM')}-0${day}` : `${currentMonth.format('YYYY-MM')}-${day}`;
    const date = currentMonth.date(day).format('YYYY-MM-DD')
    const url = moodrepURLsByDate[date] || null;

    return url;

  };
  const boxes = Array.from({ length: 5 });

  return (
    <div className="prevent_overflow">
      <Grid container alignItems={"center"} justifyContent={"center"} className="content">
        <Grid mb={5} xl={6} lg={7} md={8} sm={11} xs={12}>
          <Grid mb={5} width={"100%"} display={"flex"} alignSelf={"center"} justifyContent={"center"} textAlign={"center"}>
            <Typography variant="h32Bold" color="primary.darkerBlue">
              Mood Tracker
            </Typography>
          </Grid>
          
          <Grid bgcolor={"#5C83BF"} color={"white"} display={"flex"} borderRadius={"10px 10px 0px 0px"} padding={"16px 24px 16px 24px"} justifyContent={"space-between"}>
            <Button onClick={handlePrevMonth}>
              <FontAwesomeIcon style={{ width: "24px", height: "24px" }} color="white" icon="fa-solid fa-less-than" size="md" />
            </Button>
            <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
              <div ref={monthRef}><Typography variant="sb24Bold">{currentMonth.format("MMMM YYYY")}</Typography></div>
            </CSSTransition>
            <Button onClick={handleNextMonth}>
              <FontAwesomeIcon style={{ width: "24px", height: "24px" }} color="white" icon="fa-solid fa-greater-than" size="md" />
            </Button>
          </Grid>

          {loading ? (
            <LoadingSpinner className="smaller_height" />
          ) : (
            <Grid display={"flex"} flexDirection={"column"} bgcolor={"white"} padding={"10px 24px 36px 24px"} gap={"32px"} borderRadius={"0px 0px 10px 10px"} boxShadow={"0px 5px 10px #EFE5DE"}>
              <TransitionGroup className="days-container">
                {daysInMonth.map((day) => (
                  <CSSTransition key={day} timeout={500} classNames="item">
                    <Grid
                      borderRadius={"8px"}
                      padding={"20px 0px 20px 0px"}
                      border="0.7px solid"
                      boxShadow={"0px 5px 10px rgba(189, 189, 189, 0.18)"}
                      className={`day ${selectedDay === day ? "selected" : ""} ${isCurrentDay(day) ? "current-day" : ""}`}
                      sx={{
                        backgroundColor: getDayColor(day),
                        color: getDayTextColor(day),
                        borderColor: getBorderColor(day),
                        transition: "background-color 0.5s",
                      }}
                      onClick={() => handleDayClick(day)}
                      ref={dayRef}
                      position={"relative"}
                    >
                      <Grid className="day-number">{day}</Grid>
                      {!isSmallScreen ? (
  // Desktop version - keep as is
  <Grid
    display={!getMoodRepImage(day) ? "none" : "flex"}
    position={"absolute"}
    top={"30px"}
    left={{ xl: "80px", lg: "60px", md: "60px", sm: "60px", xs: "30px" }}
    sx={{ borderColor: getBorderColor(day) }}
    border="1px solid"
    boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.1)"}
    width={"40px"}
    height={"40px"}
    borderRadius={"100%"}
    bgcolor={"white"}
    className="moodrep-image-container"
  >
    {getMoodRepImage(day) && (
      <img 
        key={getMoodRepImage(day)} 
        src={getMoodRepImage(day)} 
        alt={`MoodRep for day ${day}`} 
      />
    )}
  </Grid>
) : (
  // Mobile version - simplified and centered
  getMoodRepImage(day) && (
    <Grid
      className="moodrep-image-container"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        '& img': {
          border: `1px solid ${getBorderColor(day)}`,
          backgroundColor: 'white',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <img 
        src={getMoodRepImage(day)} 
        alt={`MoodRep for day ${day}`}
      />
    </Grid>
  )
)}
                    </Grid>
                  </CSSTransition>
                ))}
              </TransitionGroup>

              <Grid display={"flex"} flexDirection={"column"} gap={"16px"} textAlign={"center"}>
                <Typography color="primary.fontBlue" variant="sb20Bold">Mood Level Scale</Typography>
                <img width={"95%"} height={isSmallScreen ? "10px" : "15px"} src={ProgressBar} style={{ alignSelf: "center" }} alt="Progress Bar"/>
                <Grid display={"flex"} mx={3} flexDirection={"row"} justifyContent={"space-between"}>
                  <Typography color="otherColors.mainBodyDark" variant="sb16Bold">Lowest</Typography>
                  <Typography color="otherColors.mainBodyDark" variant="sb16Bold">Highest</Typography>
                </Grid>
              </Grid>

              <Grid display={"flex"} alignSelf={"center"}>
                <Button variant="primary" onClick={() => navigate("/session")}>
                  <Typography variant="sb16Bolder">Start New Session</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Modal
        show={sessionNotFound}
        onHide={() => setSessionNotFound(false)}
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div style={{ backgroundColor: "rgb(228, 238, 249)", borderRadius: "10px" }}>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">
              No sessions found. Try one?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Typography variant="body1Regular">With Mood Tracker you can access your previous sessions and create a collection. Each day of your session is colored according to the color of your mood level and you can access the details of that day's session by clicking.<span className="fw-bold"><em> Start now to create your own collection!</em></span></Typography>
              <Grid
                display="flex"
                justifyContent="center"
              >
                <Button
                  onClick={() => navigate("/session")}
                  variant="primary"
                >
                  <Typography variant="sb20SemiBold">Start a Session</Typography>
                </Button>
              </Grid>

            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showModal}
        onHide={() => { setShowModal(false); setShowVisitModal(false) }}
      >
        <Grid borderRadius={"10px"} padding={"0px 0px 36px 0px"} display={"flex"} flexDirection={"column"} gap={"32px"} >
          <Grid bgcolor={"#5C83BF"} padding={"16px 24px 16px 24px"} borderRadius={"6px 6px 0px 0px"}>
            <Typography variant="sb24Bold" color={"white"}>
              Actions for {selectedDay && `${selectedDay}, `}
              {currentMonth.format("MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid>
            {showVisitModal ? (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant="secondary"
                  onClick={() => setShowMoodLevelModal(true)}
                  sx={{ mb: 3 }}
                >
                  <Typography variant="body1Medium"> Change Your Mood Level</Typography>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    const date = currentMonth.date(selectedDay).format("YYYY-MM-DD");
                    setShowModal(false);
                    navigate(`/overview?selectedDate=${date}`);
                    // setTimeout(() => {
                    //   // window.location.reload();
                    // });
                  }}
                >
                  <Typography variant="body1Medium">Show Session Analysis</Typography>
                </Button>
              </Grid>
            ) : (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  variant="secondary"
                  onClick={() => setShowMoodLevelModal(true)}
                  sx={{ mb: 3 }}
                >
                  <Typography variant="body1Medium">Record Your Mood Level</Typography>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate("/session")}
                  style={{ display: selectedDay === dayjs().date() ? "block" : "none" }}
                >
                  <Typography variant="body1Medium">Start a Session</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        show={showMoodLevelModal}
        onHide={() => setShowMoodLevelModal(false)}
      >
        <Grid bgcolor={"white"} borderRadius={"10px"}>
          <Grid padding={"16px 5px 16px 5px"} display={"flex"}>
            <Button
              onClick={handleBack}
              className="me-3 text-blue"
              sx={{ border: "none", background: "transparent" }}
            >
              <SvgIcon component={ArrowBack} />
            </Button>
            <Typography variant="sb24Bold" color={"#4C6EA2"}>
              Choose your mood level
            </Typography>
          </Grid>
          <Grid padding={"0px 20px 36px 20px"}>
            <Grid display={"flex"} justifyContent={"center"} sx={{ my: 3 }} >
              <CustomSlider aria-label="moodLevel" value={selectedBox} onChange={handleSliderChange} name='moodLevel' min={1} max={5} step="1" marks />
            </Grid>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Typography variant="body1Regular">Lowest</Typography>
              <Typography variant="body1Regular">Highest</Typography>
            </Grid>
            <Grid display={"flex"} justifyContent={"center"} sx={{ mt: 1 }}>
              <Button
                variant="primary"
                sx={{ width: "50%" }}
                onClick={() => handleEnergyLevelSelect(selectedDay, selectedBox)}
              >
                <Typography variant="body1Medium">Save</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default Calendar;
