import React, { useEffect, useState } from 'react';
import { Row, Col,  Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

const FAQ = () => {
  const faqs = [
    {
      id: 1,
      question: 'What is PokaMind?',
      answer: 'PokaMind is an AI-powered mental health tool by PokaMind that analyzes facial expressions, tone of voice and speech patterns to provide insights into your communication styles.'
    },
    {
      id: 2,
      question: 'How does PokaMind work?',
      answer: 'You record a 3-minute monologue which is analyzed by AI to detect communication styles, themes, and patterns. You receive a report with visualizations, metrics, and personalized advice.'
    },
    {
      id: 3,
      question: 'What are the key features of PokaMind?',
      answer: 'Key features include communication styles detection, topic analysis, speech patterns, visualizations, advice engine, and integration with therapy.'
    },
    {
      id: 4,
      question: 'Can I use PokaMind without a therapist?',
      answer: 'Yes, you can. While PokaMind can be a valuable tool for enhancing therapy, it can also be used independently for self-reflection and understanding your own communication styles better. However, please remember that PokaMind is not a replacement for professional mental health support.'
    },
    {
      id: 5,
      question: 'How is my data handled?',
      answer: 'PokaMind uses the latest security protocols and only anonymized data is stored. Your personal information is kept confidential.'
    },
    {
      id: 6,
      question: 'Is PokaMind suitable for minors?',
      answer: 'Currently PokaMind is only intended for adult use as those under 18 require parental consent.' 
    },
    {
      id: 7,
      question: 'What languages does PokaMind support?',
      answer: 'PokaMind currently most accurate in English. You can use PokaMind in any other languages, but the results may not be as accurate.'
    },
    {
      id: 8,
      question: 'How often should I use PokaMind?',
      answer: 'For best results, aim to use PokaMind 3-4 times per week for regular check-ins and tracking of your progress.'
    },
    { 
      id: 9,
      question: 'Can I share my PokaMind results with my therapist?',
      answer: 'Yes, you can share your PokaMind analyses by going through them together in a therapy session.'
    },
    {
      id: 10,
      question: 'Is there a cost for using PokaMind?',
      answer: 'PokaMind is currently in invite-only alpha testing. Once launched, various subscription plans will be available.'
    },
    {
      id: 11,
      question: 'What is represented by the moodrep at this time?',
      answer: 'In the current Alpha version, the moodrep is displayed simply as an achievement for finishing a session. In future iterations, it will represent the user\'s overall communication styles during the session.'
    },
    {
      id: 12,
      question: 'The communication styles timeline graph appears cluttered. Is there a method to filter certain communication styles?',
      answer: 'You can filter specific communication style curves in the graph by clicking on the communication style names or colors in the legend beside the graph. This allows you to toggle communication styles on or off to reduce clutter.'
    },
    {
      id: 13,
      question: 'How may I access my previous sessions?',
      answer: 'You may access your previous sessions by navigating to the myMood page and selecting a date on which you recorded a session. This will redirect you to the overview page for that particular session.'
    },
    {
      id: 14,
      question: 'Why am i talking to dog?',
      answer: ' While we cannot speak for Sigmund Freud himself, it is known that Freud had a deep love for dogs and even had a dog of his own. Understanding Freud love for dogs can provide some insight into why we have chosen a dog as our avatar.'
    },

];

const [expandedId, setExpandedId] = useState(null);

const handleToggle = (id) => {
  setExpandedId((prevId) => (prevId === id ? null : id));
};

return (
  <div className="prevent_overflow">
    <Row className="d-flex justify-content-center align-items-center content">
      <Col md={6} className="p-5">
      <h1 className="mb-4">
        Frequently Asked Questions
      </h1>
      {faqs.map((faq) => (
          <Card key={faq.id} className="faq-card">
           <Card.Header
              className={`faq-header ${expandedId === faq.id ? 'active' : ''}`}
              onClick={() => handleToggle(faq.id)}
            >
              <div className="fw-bold">
                {faq.question}
              </div> 
              <FontAwesomeIcon
                  icon={expandedId === faq.id ? faCaretUp : faCaretDown}
                  className="faq-icon"
                />
            </Card.Header>
            <CSSTransition
              in={expandedId === faq.id}
              timeout={500}
              classNames="faq-transition"
              unmountOnExit
            >
              <div><Card.Body>{faq.answer}</Card.Body></div>
            </CSSTransition>
          </Card>
        ))}
      </Col>
    </Row>
      <h4 className="text-center my-5">
        Question not on the list? Contact
          <a href="mailto:team@pokamind.com" className="mx-2 text-blue text-decoration-none">
            team@pokamind.com
          </a>
        to add your question.
      </h4>
  </div>
);
};

export default FAQ;


